<template>
  <div>
    <!-- <div class="a">404 Not Found</div>
    <div class="b">nginx</div> -->
    <div class="container">
        <h1>404 Not Found</h1>

        <hr>
        <div class="nginx">nginx</div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'no',
  components: {

  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {


  }
}
</script>
<style lang="scss" scoped>
.pic-404__parent {
 width: 80%;
 margin: 100px 0 0 20%;
}
.a {
  font-size: 20px;
  // font-weight: ;
}
// body {
//             background-color: white;
//             color: black;
//             font-family: "Times New Roman", Times, serif;
//             margin: 0;
//             padding: 0;
//         }
// .container {
//     width: 600px;
//     margin: 40px auto;
// }
h1 {
    // color: #a94442;
    font-size: 26px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}
hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0 15px;
}
.nginx {
  width: 100%;
  text-align: center;
  font-size: 15px;
}
</style>